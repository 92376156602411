import React, { useEffect, useState, useRef } from 'react';
import { css } from '@emotion/react';
import Magnify from '../assets/Magnify.svg';
import BlueArrowRight from '../assets/BlueArrowRight.svg';
import PropTypes from 'prop-types';
import searchBarStyle from '../styles/searchBar.scss'
import { useIntl } from "gatsby-plugin-intl"

const SearchBar = ({ id, style }) => {
    const { formatMessage } = useIntl();
    const [searchValue, setSearchValue] = useState('');
    const inputRef = useRef();

    useEffect(() => {
        setSearchValue(id)
    }, [id])
    const onSubmitClick = (e) => {
        e.preventDefault();
        window.location.href = `/search/#${searchValue}`
        return false;
    }
    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSubmitClick(e)
        }
    }

    return (
        <>
            <form role='search' action='search' className='form searchForm' onSubmit={onSubmitClick} css={[css(searchBarStyle), css(style)]} >
                <label htmlFor='search' className='sr-only'>{formatMessage({ id: 'searchBarButton' })}</label>
                <button className='magnify' aria-label="search bar" onClick={(e) => { e.preventDefault(); inputRef.current.focus() }}><Magnify /></button>
                <input type='type' value={searchValue} name='search' placeholder={formatMessage({ id: 'searchBarInput' })} className='input input--text' onChange={(e) => { setSearchValue(e.target.value) }} ref={inputRef} onKeyDown={onKeyDown} />
                {
                    searchValue && <button type='submit' className='search'>Search<BlueArrowRight /></button>
                }
            </form>
        </>

    )
}

const SearchBarStyled = (props) => (
    <SearchBar {...props} style={props.style} />
);

SearchBar.propTypes = {
    style: PropTypes.string,
    id: PropTypes.string,
};

SearchBar.defaultProps = {
    style: '',
    id: ''
};

SearchBarStyled.propTypes = {
    style: PropTypes.string,
    id: PropTypes.string,
};

SearchBarStyled.defaultProps = {
    style: '',
    id: ''
};

export default SearchBarStyled;