import React from "react";
import PropTypes from 'prop-types';
import { css } from "@emotion/react"
import FooterLogo from '../assets/BurlingtonOHT-logo-white.svg';
import footerStyle from '../styles/footer.scss';
import { removeWPLink } from '../utils'
import { useIntl } from "gatsby-plugin-intl"

const FooterMenu = ({ data, style }) => {
    const { formatMessage } = useIntl();

    const {
        menuItems: [
            {
                children: upperChildren
            } = {},
            {
                children: lowerChildren
            } = {}
        ] = [],
    } = data;

    return (
        <div css={[css(footerStyle), css(style)]}>
            <footer>
                <div className="container">
                    <div className="upper-footer">
                        <div className="upper-footer-logo">
                            <FooterLogo />
                            <p>{formatMessage({ id: 'footerMenuRights' })}</p>
                            <p>{formatMessage({ id: 'footerMenuTeam' })}</p>
                        </div>
                        {
                            upperChildren && upperChildren.map((column) => (
                                <div className="upper-footer-section" key={column.id}>
                                    {column.label !== '/' && <h3>{column.label}</h3>}
                                    <ul>
                                        {
                                            column.children.map((listItem) => (
                                                <li key={listItem.id}><a href={removeWPLink(listItem.path)}>{listItem.label}</a></li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            ))
                        }
                    </div>
                    {
                        lowerChildren && (
                            <div className="lower-footer">
                                <ul>
                                    {
                                        lowerChildren.map((row, i) => (
                                            <li key={row.id}><a href={removeWPLink(row.path)}>{row.label}</a></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        )
                    }
                </div>
            </footer>
        </div>
    )
}

const FooterMenuStyled = (props) => (
    <FooterMenu {...props} style={props.style} />
);

FooterMenu.propTypes = {
    data: PropTypes.shape({}),
    style: PropTypes.string
};

FooterMenu.defaultProps = {
    data: {},
    style: ''
};

FooterMenuStyled.propTypes = {
    data: PropTypes.shape({}),
    style: PropTypes.string
};

FooterMenuStyled.defaultProps = {
    data: {},
    style: ''
};

export default FooterMenuStyled;