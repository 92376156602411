import React, { useEffect } from 'react';
import { changeGoogleStyles, addScript, addScriptText } from '../utils';

const GoogleTranslate = () => {
    useEffect(() => {
        addScript();
        addScriptText();
        changeGoogleStyles();
    }, []);

    return (
        <div id="google_translate_element" />
    );
}

export default GoogleTranslate;