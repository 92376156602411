import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { removeWPLink, externalLink } from '../utils'
import headerMenuStyle from '../styles/headerMenu.scss'

export const Menu = ({ menuData, menuColor }) => {
    return (
        <>
            {
                menuData.length > 0 ? (
                    <ul css={css`
                        border-color: ${menuColor} !important;
                        li:hover{
                            border-left-color: ${menuColor} !important;
                        }
                        li:focus-within{
                            border-left-color: ${menuColor} !important;
                        }
                    `}>
                        {
                            menuData.map((menu, i) => (
                                <li key={i}>
                                    <a href={removeWPLink(menu.path)} {...removeWPLink(menu.path.includes('http') && externalLink)} >{menu.label}</a>
                                    <Menu menuData={menu.children} menuColor={menuColor} />
                                </li>
                            ))
                        }
                    </ul>
                ) : null
            }
        </>
    )
}

const HeaderMenu = ({ data, style }) => {
    const colors = ['#1D68AA', '#F2685D', '#0091CB', '#FBAE38', '#64BABF', '#1D68AA']
    return (
        <div
            className='header-menu-container' css={[css(headerMenuStyle), css(style)]}>
            <nav>
                <ul className="primary-menu-list">
                    {
                        data.menuItems.map((primaryMenu, index) => (
                            <li key={index} css={css`
                                border-color: ${colors[index]} !important;
                                &:hover {
                                    > a{
                                        border-bottom-color: ${colors[index]} !important;
                                    }
                                }
                                &:focus-within {
                                    > a{
                                        border-bottom-color: ${colors[index]} !important;
                                    }
                                }
                               
                            `}>
                                <a className="primary-menu-list-item" href={removeWPLink(primaryMenu.path)} {...removeWPLink(primaryMenu.path.includes('http') && externalLink)} >{primaryMenu.label}</a>
                                {primaryMenu.children.length > 0 && <Menu menuData={primaryMenu.children} menuColor={colors[index]} />}
                            </li>
                        ))
                    }
                </ul>
            </nav>
        </div>
    )
}

const HeaderMenuStyled = (props) => (
    <HeaderMenu {...props} style={props.style} />
);

HeaderMenu.propTypes = {
    data: PropTypes.shape({}),
    style: PropTypes.string,
};

HeaderMenu.defaultProps = {
    data: {},
    style: '',
};

HeaderMenuStyled.propTypes = {
    data: PropTypes.shape({}),
    style: PropTypes.string,
};

HeaderMenuStyled.defaultProps = {
    data: {},
    style: '',
};

export default (HeaderMenuStyled);
