import React from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import optionsDropdownStyle from '../styles/optionsDropdown.scss'
import { useIntl } from "gatsby-plugin-intl"

const OptionsFollowDropdown = ({ style }) => {
    const { formatMessage } = useIntl();
    return (
        <ul css={[css(optionsDropdownStyle), css(style)]} className='options-dropdown follow'>
            <li>
                <button className="twitter" onClick={() => window.open(formatMessage({ id: 'twitterLink' }),'_blank')}>{formatMessage({ id: 'optionsDropdownTwitter' })}</button>
            </li>
            <li>
                <button className="linkedin" onClick={() => window.open(formatMessage({ id: 'linkedInLink' }),'_blank')}>{formatMessage({ id: 'optionsDropdownLinkedIn' })}</button>
            </li>
            <li>
                <button className="facebook" onClick={() => window.open(formatMessage({ id: 'facebookLink' }),'_blank')}>{formatMessage({ id: 'optionsDropdownFacebook' })}</button>
            </li>
        </ul>
    )
}

const OptionsDropdownStyled = (props) => (
    <OptionsFollowDropdown {...props} style={props.style} />
);

OptionsFollowDropdown.propTypes = {
    style: PropTypes.string
};

OptionsFollowDropdown.defaultProps = {
    style: ''
};

OptionsDropdownStyled.propTypes = {
    style: PropTypes.string
};

OptionsDropdownStyled.defaultProps = {
    style: ''
};

export default OptionsDropdownStyled;
