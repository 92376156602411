import React, { useState } from 'react';
import Magnify from '../assets/Magnify.svg';
import PropTypes from 'prop-types';
import Hamburger from '../assets/Hamburger.svg';
import { css } from '@emotion/react';
import Options from '../components/Options';
import SearchBar from './SearchBar';
import mobileHeaderMenuStyle from '../styles/mobileHeaderMenu.scss'
import { removeWPLink, externalLink } from '../utils'

export const Menu = ({ menuData, indexNumber }) => {
    return (
        <>
            {
                menuData.length > 0 ? (
                    <ul key={menuData[0].id} css={css`
                        padding-right: calc(${indexNumber > 1 ? indexNumber * 5 : '25'}px + ${indexNumber > 1 ? '25px' : '1.0375em'})!important;
                    `}>
                        {
                            menuData.map((menu, index) => (
                                <li key={index}>
                                    <div className='menu-item'>
                                        <a href={removeWPLink(menu.path)} {...removeWPLink(menu.path.includes('http') && externalLink)} >{menu.label}</a>
                                        {
                                            menu.children.length > 0 && <button className="arrow-btn" onClick={setParentClass} onKeyDown={keyDownSetParentClass} aria-label="arrow select" ></button>
                                        }
                                    </div>
                                    <Menu menuData={menu.children} indexNumber={indexNumber + 1} />
                                </li>
                            ))
                        }
                    </ul>
                ) : null
            }
        </>
    )
}

const MobileHeaderMenu = ({ data, style }) => {
    const [menuActive, setMenuActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);

    const colors = ['#1D68AA', '#F2685D', '#0091CB', '#FBAE38', '#64BABF', '#1D68AA'];

    const searchBtnClick = (e) => {
        e.preventDefault();
        setSearchActive(!searchActive)
    }

    const menuBtnClick = (e) => {
        e.preventDefault();
        setMenuActive(!menuActive)
        Array.from(document.querySelectorAll('.active')).forEach((node) => {
            node.classList.remove('active');
        });
    }

    const magnifyKeyDown = (e) => {
        if (e.key === 'Enter') {
            searchBtnClick(e)
        }
    }
    const menuKeyDown = (e) => {
        if (e.key === 'Enter') {
            menuBtnClick(e)
        }
    }

    return (
        <div className='right-container-mobile' css={[css(mobileHeaderMenuStyle), css(style)]}>
            <div className='container'>
                <div className='header-selection'>
                    <button className='magnify' onClick={searchBtnClick} onKeyDown={magnifyKeyDown} aria-label="arrow select" ><Magnify /></button>
                    <button className='hamburger' onClick={menuBtnClick} onKeyDown={menuKeyDown} aria-label="arrow select"><Hamburger /></button>
                </div>
                <div className='menu'>
                    <nav>
                        <div className={"search-mobile-menu " + (searchActive ? 'is-active' : '')}>
                            <SearchBar />
                        </div>
                        <div className={"mobile-main-menu " + (menuActive ? 'is-active' : '')} >
                            {
                                data.menuItems.map((primaryMenu, index) => (
                                    <li className='primary-menu' css={css`
                                        color: black;
                                        margin: 0;
                                        &:focus-within {
                                            > a {
                                                border-bottom-color: ${colors[index]}
                                            }
                                        }
                                        &:hover {
                                            > a {
                                                border-bottom-color: ${colors[index]}
                                            }
                                        }
                                        .primary-menu-bar {
                                            border-color: ${colors[index]} !important;
                                            border-left: 6px solid black;
                                        }
                                        
                                    `}
                                        key={index}
                                    >
                                        <div className='primary-menu-bar menu-item'>
                                            <a href={removeWPLink(primaryMenu.path)} {...removeWPLink(primaryMenu.path.includes('http') && externalLink)}  >{primaryMenu.label}</a>
                                            {
                                                primaryMenu.children.length > 0 && <button className="arrow-btn" onClick={setParentClass} onKeyDown={keyDownSetParentClass} aria-label="arrow select" ></button>
                                            }
                                        </div>
                                        {primaryMenu.children.length > 0 && <Menu menuData={primaryMenu.children} menuColor={colors[index]} indexNumber={1} />}
                                    </li>
                                ))
                            }
                            <Options className={'options-mobile'} />
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}

const setParentClass = (e) => {
    e.stopPropagation();
    e.preventDefault();
    e.target.parentElement.classList.toggle('active');
}
const keyDownSetParentClass = (e) => {
    if(e.key === 'Enter') {
        setParentClass(e)
    }
}

const MobileHeaderMenuStyled = (props) => (
    <MobileHeaderMenu {...props} style={props.style} />
);

MobileHeaderMenu.propTypes = {
    data: PropTypes.shape({}),
    style: PropTypes.string
};

MobileHeaderMenu.defaultProps = {
    data: {},
    style: ''
};

MobileHeaderMenuStyled.propTypes = {
    data: PropTypes.shape({}),
    style: PropTypes.string
};

MobileHeaderMenuStyled.defaultProps = {
    data: {},
    style: ''
};

export default MobileHeaderMenuStyled;
