import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import Header from './Header';
import FooterMenu from './FooterMenu';
import layoutStyle from '../styles/layout.scss'
import printStyle from '../styles/print.scss';
import { withBreakpoints } from 'gatsby-plugin-breakpoints';
import { getMenuHeirarchy } from '../utils';
import { useIntl } from "gatsby-plugin-intl"

import Notifications from './Notifications';

const Layout = ({ children, style }) => {
  const { formatMessage } = useIntl();
  const [headerMenu, setHeaderMenu] = useState(null);
  const [footerMenu, setFooterMenu] = useState(null);
  const [notifications, setNotifications] = useState(null);

  const data = useStaticQuery(graphql`
    {
      allWpBannerNotification(sort: { fields: date, order: DESC }) {
        edges {
          node {
            id
            BannerNotificationFields {
              bannerColour
              body
              header
              linkText
              linkUrl
              
            }
          }
        }
      }
      allWpCookieNotification(sort: { fields: date, order: DESC }) {
        edges {
          node {
            id
            CookieNotificationFields {
              body
              header
              linkText
              linkUrl
              
            }
          }
        }
      }
      

      allWpMenu {
        nodes {
          menuItems {
            nodes {
              url
              id
              label
              parentId
              path
            }
          }
          id
          name
        }
      }
    }
  `);

  useEffect(() => {
    const {
      allWpBannerNotification: { edges: bannerEdges } = {},
      allWpCookieNotification: { edges: cookieEdges } = {},
      allWpMenu: { nodes } = {},
    } = data

    console.log('data', data)

    const [firstBanner] = bannerEdges;
    const [firstCookie] = cookieEdges;

    setNotifications({
      banner: {
        id: firstBanner?.node?.id,
        ...firstBanner?.node?.BannerNotificationFields,
      },
      cookie: {
        id: firstCookie?.node?.id,
        ...firstCookie?.node?.CookieNotificationFields,
      },
    });

    const updated = getMenuHeirarchy(nodes);
    const headerMenu = updated.filter(menu => menu.name === 'Header Menu');
    const footerMenu = updated.filter(menu => menu.name === 'Footer Menu');
    setHeaderMenu(headerMenu[0]);
    setFooterMenu(footerMenu[0]);
  }, []);


  return (
    <>
    {notifications && <Notifications data={notifications} />}
    <div
      className='layout-container'
      css={[css(style), css(layoutStyle), css(printStyle)]}
    >
      <a className='skip-main' href='#main'>{formatMessage({ id: 'skipContent' })}</a>
      {
        headerMenu && <Header data={headerMenu} />
      }
      <main id='main'>{children}</main>
      {
        footerMenu && <FooterMenu data={footerMenu} />
      }
    </div>
      </>
  )
}

const LayoutPageStyled = (props) => (
  <Layout {...props} style={props.style} />
);

Layout.propTypes = {
  children: PropTypes.any,
  style: PropTypes.string
};

Layout.defaultProps = {
  children: [],
  style: ''
};

LayoutPageStyled.propTypes = {
  children: PropTypes.any
};

LayoutPageStyled.defaultProps = {
  children: [],
};

export default withBreakpoints(LayoutPageStyled);
