import React, { useEffect } from 'react';
import OptionsFollowDropdown from './OptionsFollowDropdown';
import OptionsDropdown from './OptionsDropdown';
import { css } from '@emotion/react';
import GoogleTranslate from './GoogleTranslate';
import PropTypes from 'prop-types';
import { changeGoogleStyles } from '../utils';
import optionsStyle from '../styles/options.scss';
import { useIntl } from "gatsby-plugin-intl"

const Options = ({ style, className }) => {
  const { formatMessage } = useIntl();
  useEffect(() => {
    window.addEventListener('resize', function () {
      changeGoogleStyles()
    })
  })

  const onKeyDown = (e, size) => {
    if (e.key === 'Enter') {
      document.querySelector('main').style.fontSize = `${size}px`;
    }
  }

  const onMagnifyClick = (size) => {
    document.querySelector('main').style.fontSize = `${size}px`;
  }

  const onPrintClick = () => {
    try {
      // Print for Safari browser
      document.execCommand('print', false, null)
    } catch {
      window.print()
    }
  }

  return (
    <div>
      <ul className={`options ${className}`} css={[css(optionsStyle), css(style)]} >
        <li className='options-box'
        >
          <div className='options-list-container'>
            <a className='share social-media' href='/'>
              <span>{formatMessage({ id: 'optionsSocialMedia' })}</span>
            </a>
            {
              className && (
                <button className='arrow-btn square-icon' onClick={setParentClass} onKeyDown={keyDownSetParentClass}>
                </button>
              )
            }
          </div>
          <OptionsFollowDropdown />
        </li>
        <li className='options-box'
        >
          <div className='options-list-container'>
            <a className='share' href='/'><span>{formatMessage({ id: 'optionsShare' })}</span></a>
            {
              className && (
                <button className='arrow-btn square-icon' onClick={setParentClass} onKeyDown={keyDownSetParentClass}>
                </button>
              )
            }
          </div>
          <OptionsDropdown />
        </li>
        <li className='options-box'>
          <button className='print' onClick={onPrintClick}>
            <span>{formatMessage({ id: 'optionsPrint' })}</span>
          </button>
        </li>
        <li className='options-box'>
          <div className='fonts'>
            <button className='s-font font-box' onClick={() => onMagnifyClick(15)} onKeyDown={(e) => onKeyDown(e, 15)}>
              {formatMessage({ id: 'optionsMagnifyS' })}
            </button>
            <button className='m-font font-box' onClick={() => onMagnifyClick(16)} onKeyDown={(e) => onKeyDown(e, 16)}>
              {formatMessage({ id: 'optionsMagnifyM' })}
            </button>
            <button className='l-font font-box' onClick={() => onMagnifyClick(17)} onKeyDown={(e) => onKeyDown(e, 17)}>
              {formatMessage({ id: 'optionsMagnifyL' })}
            </button>
          </div>
        </li>
        <li className='options-box'>
          <a className="feedback" href='https://forms.microsoft.com/r/Veu76CvtzJ' target='_blank' rel="noreferrer">{formatMessage({ id: 'optionsFeedback' })}</a>
        </li>
        <li className='options-box'>
          <GoogleTranslate />
        </li>
      </ul>
    </div>
  )
}

const setParentClass = (e) => {
  e.stopPropagation();
  e.preventDefault();
  e.target.parentElement.classList.toggle('active');
}
const keyDownSetParentClass = (e) => {
  if (e.key === 'Enter') {
    setParentClass(e)
  }
}

const OptionsStyled = (props) => (
  <Options {...props} style={props.style} />
);

Options.propTypes = {
  style: PropTypes.string,
  className: PropTypes.string,
};

Options.defaultProps = {
  style: '',
  className: '',
};

OptionsStyled.propTypes = {
  style: PropTypes.string,
  className: PropTypes.string,
};

OptionsStyled.defaultProps = {
  style: '',
  className: '',
};

export default OptionsStyled;
