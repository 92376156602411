import React, { useState } from "react"
import CookieService from "../../services/CookieService";
import GoogleAnalyticsService from "../../services/GoogleAnalyticsService";
import _ from "lodash";

const CookieConsent = ({ notification, id }) => {

  const { header, body, linkText, linkUrl } = notification;

  const handleStartup = () => {
    if (!id) return false;
  
    if (CookieService.isSet("Cookie")) {
      // Shows new cookie post regardless if cookie has been closed previously (and saved as a decision)
      return !_.isEqual(CookieService.getAttribute("Cookie"), { notification, id });
    }
  
    return true;
  };
  

  const [open, setOpen] = useState(handleStartup())

  const handleAccept = () => {
    CookieService.setAttribute("CookieConsent", { consent: true })
    CookieService.setAttribute("Cookie", { notification, id })
    
    // Re-initialize Google Analytics after consent is given
    GoogleAnalyticsService.initialize();

    setOpen(false)
  }
  
  
  const handleDecline = () => {
    CookieService.clear();
    CookieService.setAttribute("CookieConsent", { consent: false })
    CookieService.setAttribute("Cookie", { notification, id })

    setOpen(false)
  }

  return open ? (
    <div className="cookie__container">
      <div className="cookie">
        <p className="cookie__header">{header}</p>
        <p className="cookie__body">{body}</p>
        <a href={linkUrl} target="_blank" rel="noreferrer" className="cookie__link">
          {linkText}
        </a>
        <button onClick={handleAccept} className="cookie__btn">Accept</button>
        <button onClick={handleDecline} className="cookie__btn cookie__btn--secondary">Decline</button>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default CookieConsent
