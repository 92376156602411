import React from "react"
import CookieConsent from "./CookieConsent"
import Banner from "./Banner"

import { css } from "@emotion/react"
import notificationStyle from "../../styles/notifications.scss"

const Notifications = ({ data }) => {

  const renderNotification = (notification, type) => {
    if (type === 'banner') return <Banner notification={notification} id={notification.id} />;
    if (type === 'cookie') return <CookieConsent notification={notification} id={notification.id} />;
  }

  return (
    <div className="layout-container" css={css(notificationStyle)}>
      {data.banner && <div>{renderNotification(data.banner, 'banner')}</div>}
      {data.cookie && <div>{renderNotification(data.cookie, 'cookie')}</div>}
    </div>
  );
};


export default Notifications
