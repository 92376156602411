import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import optionsDropdownStyle from '../styles/optionsDropdown.scss'
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    TwitterIcon,
    FacebookIcon,
    EmailIcon,
    LinkedinIcon
} from 'react-share';
import { useIntl } from "gatsby-plugin-intl"

const OptionsDropdown = ({ style }) => {
    const { formatMessage } = useIntl();
    const title = formatMessage({ id: 'optionsDropdownTitle' });
    const hashtag = formatMessage({ id: 'optionsDropdownSocialHashTag' });

    const [currentURL, setCurrentURL] = useState('');
    useEffect(() => {
        const isBrowser = typeof window !== "undefined"
        if (isBrowser) {
            setCurrentURL(window.location.href)
        }
    }, []);

    return (
        <ul css={[css(optionsDropdownStyle), css(style)]} className='options-dropdown'>
            <li >
                <FacebookShareButton url={currentURL} quote={title} hashtag={hashtag} className='Demo__some-network__share-button' target='_blank' rel="noreferrer">
                    <FacebookIcon size={32} round />
                    {formatMessage({ id: 'optionsDropdownFacebook' })}
                </FacebookShareButton>
            </li>
            <li >
                <LinkedinShareButton url={currentURL} source={'/'} title={title} className='Demo__some-network__share-button'>
                    <LinkedinIcon size={32} round />
                    {formatMessage({ id: 'optionsDropdownLinkedIn' })}
                </LinkedinShareButton>
            </li>
            <li >
                <TwitterShareButton url={currentURL} title={title} className='Demo__some-network__share-button'>
                    <TwitterIcon size={32} round />
                    {formatMessage({ id: 'optionsDropdownTwitter' })}
                </TwitterShareButton>
            </li>
            <li >
                <EmailShareButton
                    url={currentURL}
                    subject={'Share Page'}
                    body={`Share Page`}
                >
                    <EmailIcon size={32} round />
                    {formatMessage({ id: 'optionsDropdownEmail' })}
                </EmailShareButton>
            </li>
        </ul>
    )
}

const OptionsDropdownStyled = (props) => (
    <OptionsDropdown {...props} style={props.style} />
);

OptionsDropdown.propTypes = {
    style: PropTypes.string
};

OptionsDropdown.defaultProps = {
    style: ''
};

OptionsDropdownStyled.propTypes = {
    style: PropTypes.string
};

OptionsDropdownStyled.defaultProps = {
    style: ''
};

export default OptionsDropdownStyled;
