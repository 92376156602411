import React, { useState } from "react";
import CookieService from "../../services/CookieService";
import _ from "lodash";

import BannerIcon from "../../assets/BannerIcon.svg";
import BannerClose from "../../assets/BannerClose.svg";

const Banner = ({ notification, id }) => {
  const { header, body, linkText, linkUrl, bannerColour } = notification;

  const handleStartup = () => {
    if (!id) return false;
  
    if (CookieService.isSet("Banner")) {
      return !_.isEqual(CookieService.getAttribute("Banner"), { notification, id });
    }
  
    return true;
  };
  

  const [open, setOpen] = useState(handleStartup())

  const handleClose = () => {
    CookieService.setAttribute("Banner", { notification, id })
    setOpen(false)
  }

  return open ? (
    <div className={`banner banner--${bannerColour}`}>
      <div className="banner__icon">
        <BannerIcon />
      </div>
      <div className="banner__inner">
        <span className="banner__header">{header + " "}</span>
        <span className="banner__body">{body + " "}</span>
        <span>
          <a href={linkUrl} target="_blank" rel="noreferrer" className="banner__link">
            {linkText}
          </a>
        </span>
      </div>
      <div className="banner__close" onClick={handleClose}>
        <BannerClose />
      </div>
    </div>
  ) : null;
}

export default Banner;

