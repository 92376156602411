import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useIntl, injectIntl } from "gatsby-plugin-intl";

const Seo = ({ title }) => {
  const { formatMessage } = useIntl();

  return (
    <Helmet>
      <html lang='en' />
      <meta http-equiv='Cache-control' content='public' charSet='utf-8' />
      <title>{`${formatMessage({ id: 'seoTitle' })} ${title}`}</title>
    </Helmet>
  )
}

Seo.propTypes = {
  title: PropTypes.string
};

Seo.defaultProps = {
  title: ''
};

export default injectIntl(Seo);